@import "variables.scss";
.storyPageContainer {
    @media screen and (min-width: $desktop-breakpoint) and (min-height: $toc_height) {
        background: linear-gradient(
            to right,
            rgba(245, 233, 209, 0.36) 75%,
            rgba(137, 104, 69, 0.44) 100%
        ); // Add the gradient if the TOC is there. This doesn't look quite right compared to Adobe XD but is very close
    }
}

.storyPage {
    @media screen and (min-width: $desktop-breakpoint) and (min-height: $toc_height) {
        margin-right: $toc_width; // only save space if the toc is visible
    }
    max-width: $page_max_width;
}
