@import "variables.scss";
.navbar {
    display: flex;
    align-items: stretch;
    width: 100%;
}

.navbar ul {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;
    flex-grow: 1;
    width: 100vw;
    overflow-x: auto;
    overflow-y: hidden;
}

.navbar .navitem {
    text-transform: uppercase;
    font-size: 15px;
    // @media screen and (max-width: $tablet-breakpoint) {
    //   font-size: 0.9375rem;
    // }

    flex-grow: 1;
    flex-basis: 15%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    //transition: flex-basis ease-in-out 0.2s, font-size ease-in-out 0.2s;

    &.selected {
        flex-basis: 55%;
        font-size: 25px;
        font-weight: $font_semibold;
        // @media screen and (max-width: $tablet-breakpoint) {
        //   font-size: 1rem;
        // }
        cursor: not-allowed;
        pointer-events: none;
    }
}

.navbar .navitem a {
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    text-align: center;
    justify-content: center;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

@media screen and (min-width: $tablet-breakpoint) {
    .navbar .navitem {
        font-size: 20px;
        &.selected {
            font-size: 50px;
        }
    }
}

.home {
    background-color: #425d29;
    color: #bfcb8d;
    max-width: 100px;
}

.originstories {
    @include origin-stories;
}

.iceagetexas {
    @include ice-age-texas;
}

.dailylife {
    @include daily-life;
}

@media screen and (max-width: $tablet-breakpoint) {
    .navbar {
        flex-wrap: wrap;
    }
    .navbar .home {
        flex-basis: 100%;
        max-width: unset;
        height: 40px;
    }
    .navbar ul {
        flex-basis: 100%;
    }
}
