@import "variables.scss";
.pageWrapper {
    padding: 40px 90px $footer_height;
    @media screen and (max-width: calc($tablet_breakpoint + 60px)) {
        padding-left: 60px;
        padding-right: 60px;
    }
    @media screen and (max-width: $mobile_breakpoint) {
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 24px;
    }
}
