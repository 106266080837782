@import "variables.scss";
.footer {
    position: relative;
    height: 60px;
    text-transform: uppercase;
}

.fixedFooter {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 20;
}

.footerNav {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
}

.footerLinks {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 20px;
    height: 100%;
    padding: 0 1rem;
    font-size: 10px;
    text-align: center;
    font-weight: $font_extrabold;
    color: $section_title_background_color;
    line-height: 11px;

    transition: color 0.2s ease-in-out;
    letter-spacing: 1px;
    z-index: 2;
    li {
        height: 90%;
        display: flex;
        flex-flow: column;
        justify-content: center;
    }
    a {
        padding: 0rem 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }
}

@media screen and (min-width: $tablet-breakpoint) {
    .footerLinks {
        font-size: 17px;
        padding: 0 3rem;
    }
}

.footerImage {
    z-index: 1;
}
