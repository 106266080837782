.StoryNavigation_navbar__r5s0N{display:flex;align-items:stretch;width:100%}.StoryNavigation_navbar__r5s0N ul{display:flex;flex-direction:row;justify-content:stretch;align-items:stretch;flex-grow:1;width:100vw;overflow-x:auto;overflow-y:hidden}.StoryNavigation_navbar__r5s0N .StoryNavigation_navitem___NovD{text-transform:uppercase;font-size:15px;flex-grow:1;flex-basis:15%;display:flex;justify-content:center;flex-direction:column;text-align:center}.StoryNavigation_navbar__r5s0N .StoryNavigation_navitem___NovD.StoryNavigation_selected__y__TQ{flex-basis:55%;font-size:25px;font-weight:500;cursor:not-allowed;pointer-events:none}.StoryNavigation_navbar__r5s0N .StoryNavigation_navitem___NovD a{flex-grow:1;height:100%;display:flex;flex-direction:column;align-items:stretch;text-align:center;justify-content:center;padding-left:1rem;padding-right:1rem;padding-top:1rem;padding-bottom:1rem}@media screen and (min-width: 767px){.StoryNavigation_navbar__r5s0N .StoryNavigation_navitem___NovD{font-size:20px}.StoryNavigation_navbar__r5s0N .StoryNavigation_navitem___NovD.StoryNavigation_selected__y__TQ{font-size:50px}}.StoryNavigation_home__qkiGT{background-color:#425d29;color:#bfcb8d;max-width:100px}.StoryNavigation_originstories__dqOPs{background-color:#bdcd86;color:#33541d}.StoryNavigation_iceagetexas__9uhk4{background-color:#8dd2ef;color:#172b66}.StoryNavigation_dailylife__svluj{background-color:#172b66;color:#8dd2ef}@media screen and (max-width: 767px){.StoryNavigation_navbar__r5s0N{flex-wrap:wrap}.StoryNavigation_navbar__r5s0N .StoryNavigation_home__qkiGT{flex-basis:100%;max-width:unset;height:40px}.StoryNavigation_navbar__r5s0N ul{flex-basis:100%}}
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;vertical-align:baseline;overscroll-behavior:none}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:"";content:none}table{border-collapse:collapse;border-spacing:0}body{background:rgba(245,233,209,.36)}body *{box-sizing:border-box;outline:none}a{text-decoration:none;color:unset}button,input,optgroup,select,textarea{font-family:inherit;font-size:100%;line-height:1.15;margin:0}button{border:unset;background-color:unset}button,input{overflow:visible}button,select{text-transform:none}button,[type=button],[type=reset],[type=submit]{-moz-appearance:button;appearance:button;-webkit-appearance:button;-webkit-tap-highlight-color:rgba(0,0,0,0)}img{pointer-events:none;-webkit-user-select:none;-moz-user-select:none;user-select:none}*{-webkit-user-drag:none;-khtml-user-drag:none;-moz-user-drag:none;-o-user-drag:none;user-drag:none}
.Footer_footer__oaUOW{position:relative;height:60px;text-transform:uppercase}.Footer_fixedFooter__V3UTu{position:fixed;bottom:0;left:0;right:0;z-index:20}.Footer_footerNav___s5l1{display:flex;flex-direction:column;justify-content:flex-end;height:100%}.Footer_footerLinks___lz5O{display:flex;align-items:flex-end;justify-content:space-between;gap:20px;height:100%;padding:0 1rem;font-size:10px;text-align:center;font-weight:700;color:#f3bb41;line-height:11px;transition:color .2s ease-in-out;letter-spacing:1px;z-index:2}.Footer_footerLinks___lz5O li{height:90%;display:flex;flex-flow:column;justify-content:center}.Footer_footerLinks___lz5O a{padding:0rem 1rem;display:flex;flex-direction:column;justify-content:center;height:100%}@media screen and (min-width: 767px){.Footer_footerLinks___lz5O{font-size:17px;padding:0 3rem}}.Footer_footerImage__oYHJ1{z-index:1}
.Header_header__R_kkv{background-image:url(/_next/static/media/header@4x.f42a1248.webp);min-height:60px;aspect-ratio:1200/84;position:relative;-webkit-clip-path:polygon(0 0, 100% 0, 100% 100%, 0% 100%);clip-path:polygon(0 0, 100% 0, 100% 100%, 0% 100%);background-size:cover;background-position:center;background-repeat:no-repeat}@media screen and (max-width: 767px){.Header_header__R_kkv{background-image:url(/_next/static/media/header@2x.569a1d67.webp)}}@media screen and (max-width: 520px){.Header_header__R_kkv{background-image:url(/_next/static/media/header-mobile@2x.4459eeac.webp);aspect-ratio:320/41;min-height:40px}}.Header_headerLink__ZBzY9{display:block;width:100%;height:100%;cursor:pointer}
.Arrowhead_arrowHead__vl0SZ{position:absolute;margin:auto;display:flex;flex-direction:column;align-items:center;gap:1rem;bottom:calc(60px + 3rem);color:#fff;left:50%;cursor:pointer;z-index:10;transform:translate(-25%)}@media screen and (max-width: 500px){.Arrowhead_arrowHead__vl0SZ{font-size:12px;bottom:calc(60px + 1rem)}}
/* vietnamese */
@font-face {
  font-family: '__Encode_Sans_da3d0b';
  font-style: normal;
  font-weight: 100 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/9d405ef680db7df5.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Encode_Sans_da3d0b';
  font-style: normal;
  font-weight: 100 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/b6ab976c14c89602.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Encode_Sans_da3d0b';
  font-style: normal;
  font-weight: 100 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/a9c858bc5bf570be.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Encode_Sans_Fallback_da3d0b';src: local("Arial");ascent-override: 103.08%;descent-override: 22.02%;line-gap-override: 0.00%;size-adjust: 99.92%
}.__className_da3d0b {font-family: '__Encode_Sans_da3d0b', '__Encode_Sans_Fallback_da3d0b';font-style: normal
}

/* vietnamese */
@font-face {
  font-family: '__Encode_Sans_Condensed_6e320b';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/cac41f66c52b804d.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Encode_Sans_Condensed_6e320b';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/9f841a40d8552b5f.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Encode_Sans_Condensed_6e320b';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/267eb635b9b2f1fc.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Encode_Sans_Condensed_Fallback_6e320b';src: local("Arial");ascent-override: 119.83%;descent-override: 25.59%;line-gap-override: 0.00%;size-adjust: 85.96%
}.__className_6e320b {font-family: '__Encode_Sans_Condensed_6e320b', '__Encode_Sans_Condensed_Fallback_6e320b';font-weight: 400;font-style: normal
}.__variable_6e320b {--encode-sans-condensed: '__Encode_Sans_Condensed_6e320b', '__Encode_Sans_Condensed_Fallback_6e320b'
}

/* latin-ext */
@font-face {
  font-family: '__Glass_Antiqua_4b41bd';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/61a29a81396ab75c.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Glass_Antiqua_4b41bd';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/53952c7d4d768839.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Glass_Antiqua_Fallback_4b41bd';src: local("Arial");ascent-override: 98.49%;descent-override: 23.59%;line-gap-override: 0.00%;size-adjust: 84.78%
}.__className_4b41bd {font-family: '__Glass_Antiqua_4b41bd', '__Glass_Antiqua_Fallback_4b41bd';font-weight: 400;font-style: normal
}.__variable_4b41bd {--glass-antiqua: '__Glass_Antiqua_4b41bd', '__Glass_Antiqua_Fallback_4b41bd'
}

/* cyrillic-ext */
@font-face {
  font-family: '__Rubik_Dirt_040a28';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/ebc949965a3ce3ce.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Rubik_Dirt_040a28';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/bae71b9659bab89c.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: '__Rubik_Dirt_040a28';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/eb92c0d85f4623c3.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: '__Rubik_Dirt_040a28';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/1f44c42f8b5b3c69.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Rubik_Dirt_040a28';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/b2a1c75b732dcdee.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Rubik_Dirt_Fallback_040a28';src: local("Arial");ascent-override: 78.34%;descent-override: 20.95%;line-gap-override: 0.00%;size-adjust: 119.36%
}.__className_040a28 {font-family: '__Rubik_Dirt_040a28', '__Rubik_Dirt_Fallback_040a28';font-weight: 400;font-style: normal
}.__variable_040a28 {--rubik-dirt: '__Rubik_Dirt_040a28', '__Rubik_Dirt_Fallback_040a28'
}

/* vietnamese */
@font-face {
  font-family: '__Encode_Sans_Condensed_24ed93';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/83297e81a85f411b.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Encode_Sans_Condensed_24ed93';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/25c4b3befa533f23.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Encode_Sans_Condensed_24ed93';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/18652e3bb39d5257.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Encode_Sans_Condensed_Fallback_24ed93';src: local("Arial");ascent-override: 119.83%;descent-override: 25.59%;line-gap-override: 0.00%;size-adjust: 85.96%
}.__className_24ed93 {font-family: '__Encode_Sans_Condensed_24ed93', '__Encode_Sans_Condensed_Fallback_24ed93';font-weight: 600;font-style: normal
}.__variable_24ed93 {--encode-sans-condensed-bold: '__Encode_Sans_Condensed_24ed93', '__Encode_Sans_Condensed_Fallback_24ed93'
}

/* vietnamese */
@font-face {
  font-family: '__Encode_Sans_6ae90a';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/9641a26fda0efca7.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Encode_Sans_6ae90a';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/199d673fef7216e7.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Encode_Sans_6ae90a';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/c5868b2abd08352f.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Encode_Sans_Fallback_6ae90a';src: local("Arial");ascent-override: 103.08%;descent-override: 22.02%;line-gap-override: 0.00%;size-adjust: 99.92%
}.__className_6ae90a {font-family: '__Encode_Sans_6ae90a', '__Encode_Sans_Fallback_6ae90a';font-weight: 600;font-style: normal
}.__variable_6ae90a {--encode-sans-bold: '__Encode_Sans_6ae90a', '__Encode_Sans_Fallback_6ae90a'
}

/* vietnamese */
@font-face {
  font-family: '__Encode_Sans_2d669e';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/a4177a2fe1f1f399.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Encode_Sans_2d669e';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/fa42b5612f28fd44.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Encode_Sans_2d669e';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/800e9df1d999aa51.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Encode_Sans_Fallback_2d669e';src: local("Arial");ascent-override: 103.08%;descent-override: 22.02%;line-gap-override: 0.00%;size-adjust: 99.92%
}.__className_2d669e {font-family: '__Encode_Sans_2d669e', '__Encode_Sans_Fallback_2d669e';font-weight: 700;font-style: normal
}.__variable_2d669e {--encode-sans-extra-bold: '__Encode_Sans_2d669e', '__Encode_Sans_Fallback_2d669e'
}

/* vietnamese */
@font-face {
  font-family: '__Encode_Sans_Condensed_083f93';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/60f83e2af1720cfc.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Encode_Sans_Condensed_083f93';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/dd06e6c2e5bcf86e.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Encode_Sans_Condensed_083f93';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/6266a4d4f837ed62.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Encode_Sans_Condensed_Fallback_083f93';src: local("Arial");ascent-override: 119.83%;descent-override: 25.59%;line-gap-override: 0.00%;size-adjust: 85.96%
}.__className_083f93 {font-family: '__Encode_Sans_Condensed_083f93', '__Encode_Sans_Condensed_Fallback_083f93';font-weight: 500;font-style: normal
}.__variable_083f93 {--encode-sans-condensed-semi-bold: '__Encode_Sans_Condensed_083f93', '__Encode_Sans_Condensed_Fallback_083f93'
}

/* vietnamese */
@font-face {
  font-family: '__Encode_Sans_Condensed_bc006e';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/fc8ea8a85cf98c28.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Encode_Sans_Condensed_bc006e';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/df66afa349b91a2f.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Encode_Sans_Condensed_bc006e';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/04d44cab8daeb13d.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Encode_Sans_Condensed_Fallback_bc006e';src: local("Arial");ascent-override: 119.83%;descent-override: 25.59%;line-gap-override: 0.00%;size-adjust: 85.96%
}.__className_bc006e {font-family: '__Encode_Sans_Condensed_bc006e', '__Encode_Sans_Condensed_Fallback_bc006e';font-weight: 700;font-style: normal
}.__variable_bc006e {--encode-sans-condensed-extra-bold: '__Encode_Sans_Condensed_bc006e', '__Encode_Sans_Condensed_Fallback_bc006e'
}

/* vietnamese */
@font-face {
  font-family: '__Encode_Sans_Condensed_aa5328';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/70404b5917f70e3b.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Encode_Sans_Condensed_aa5328';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/a5d6522757e83e08.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Encode_Sans_Condensed_aa5328';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/9a483165131a9ab4.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Encode_Sans_Condensed_Fallback_aa5328';src: local("Arial");ascent-override: 119.83%;descent-override: 25.59%;line-gap-override: 0.00%;size-adjust: 85.96%
}.__className_aa5328 {font-family: '__Encode_Sans_Condensed_aa5328', '__Encode_Sans_Condensed_Fallback_aa5328';font-weight: 900;font-style: normal
}.__variable_aa5328 {--encode-sans-condensed-black: '__Encode_Sans_Condensed_aa5328', '__Encode_Sans_Condensed_Fallback_aa5328'
}

.Page_pageWrapper__yH3qv{padding:40px 90px 60px}@media screen and (max-width: 827px){.Page_pageWrapper__yH3qv{padding-left:60px;padding-right:60px}}@media screen and (max-width: 480px){.Page_pageWrapper__yH3qv{padding-left:24px;padding-right:24px;padding-top:24px}}
@media screen and (min-width: 1024px)and (min-height: 775px){.StoryPage_storyPageContainer__F01fj{background:linear-gradient(to right, rgba(245, 233, 209, 0.36) 75%, rgba(137, 104, 69, 0.44) 100%)}}.StoryPage_storyPage__txaDV{max-width:1200px}@media screen and (min-width: 1024px)and (min-height: 775px){.StoryPage_storyPage__txaDV{margin-right:257px}}
