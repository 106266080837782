@import "variables.scss";
.header {
    background-image: url('../components/images/header/header@4x.webp');
    min-height: 60px;
    aspect-ratio: 1200/84;
    position: relative;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @media screen and (max-width: calc($tablet-breakpoint)) {
        background-image: url('../components/images/header/header@2x.webp');
    }
    @media screen and (max-width: calc($mobile-breakpoint + 40px)) {
        background-image: url('../components/images/header/header-mobile@2x.webp');
        aspect-ratio: 320/41;
        min-height: 40px;
    }
}
// anchor element that expands to fit container
.headerLink {
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
}
